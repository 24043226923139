<template>
  <n-layout-header class="layout__header">
    <n-space
      align="center"
      :wrap="false"
      style="overflow: hidden"
    >
      <span class="logo-text">HG3</span>
      <Breadcrumb />
    </n-space>
    <n-space
      align="center"
      :wrap="false"
      :wrap-item="false"
    >
      <LayoutsMainProfileDetails />
      <n-switch
        :value="isDark"
        size="large"
        @update:value="onSwitch"
      >
        <template #icon>
          <n-icon :component="isDark ? SunnyOutline : MoonOutline" />
        </template>
      </n-switch>
      <n-dropdown :options="profileDropdownOptions">
        <n-icon
          :component="PersonOutline"
          size="26"
          color="#fff"
          style="cursor: pointer"
        />
      </n-dropdown>
    </n-space>
  </n-layout-header>
</template>

<script setup>
import { useUiStore } from '@/stores/ui'
import { computed, h } from 'vue'
import { useRouter } from 'vue-router'
import Breadcrumb from '@/layouts/main/LayoutsMainBreadcrumb.vue'
import {
  PeopleOutline,
  LogOutOutline,
  LanguageOutline,
  SunnyOutline,
  MoonOutline,
  PersonOutline,
} from '@vicons/ionicons5'
import { NIcon } from 'naive-ui'
import { useTranslationStore } from '@/stores/translation'
import { useAuthStore } from '@/stores/auth'

const router = useRouter()

const authStore = useAuthStore()
const uiStore = useUiStore()
const translationStore = useTranslationStore()

const isDark = computed(() => uiStore.theme === 'dark')

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const onSwitch = value => {
  uiStore.setTheme(value ? 'dark' : 'light')
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const renderIcon = icon => () => h(NIcon, null, { default: () => h(icon) })

const profileDropdownOptions = computed(() => [
  {
    label: 'Сменить профиль',
    key: 'changeProfile',
    icon: renderIcon(PeopleOutline),
    props: {
      onClick: () => {
        router.push('/profiles')
      },
    },
  },
  {
    label: 'Язык',
    key: 'language',
    icon: renderIcon(LanguageOutline),
    children: (translationStore.languages || []).map(lang => ({
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      label: lang.value,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      key: 'lang- ' + lang.value,
      props: {
        onClick: () => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          translationStore.selectLanguage(lang.id)
        },
        style: {
          color:
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            translationStore.selectedLanguage?.id === lang?.id
              ? 'var(--hg-primary-color)'
              : undefined,
        },
      },
    })),
  },
  {
    label: 'Выйти',
    key: 'logout',
    icon: renderIcon(LogOutOutline),
    props: {
      onClick: () => {
        authStore.makeLogout().then(() => {
          router.push('/login')
        })
      },
    },
  },
])
</script>
<style lang="scss">
.layout__header {
  height: 46px;
  background-color: var(--hg-primary-color-header);
  padding: 7px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-text {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.project-select {
  .n-base-selection-input__content {
    color: #fff;
  }

  .n-base-selection-label {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  .n-base-selection__border {
    display: none;
  }
}
</style>
