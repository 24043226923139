import request from '@/api/request'
import { IGetPositionsResponse } from './models'

export const getPositions = (data?: unknown): Promise<IGetPositionsResponse> => {
  return request({
    url: `/api/v1/contact/positions`,
    method: 'GET',
    params: data,
    data: null,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deletePosition = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/contact/positions/${id}`,
    method: 'DELETE',
    data: null,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createPosition = data => {
  return request({
    url: `/api/v1/contact/positions`,
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const updatePosition = (id, data) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/contact/positions/${id}`,
    method: 'PUT',
    data,
  })
}
