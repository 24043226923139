import { tableConfig, errorRequiresAttentionFilterValues } from '@/api/paymentImport/mock'
import request, { IResponseList } from '@/api/request'
import { IPaymentImportPackage } from '@/api/paymentImport/models'
import { paymentImportRegistryConfig } from '@/views/paymentImportPackages/config/paymentImportRegistry.frontconf'
import {
  IGetPackageStatusesQueryParams,
  IPackageImportPackageDetails,
  ISearchPackageImportDetailsParams,
} from '@/models/entities/paymentImport'

export const getTableConfig = () => Promise.resolve(tableConfig)

export const getPaymentImportErrorRequiresAttentions = () =>
  Promise.resolve(errorRequiresAttentionFilterValues)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getImportConfigurations = params => {
  return request({
    url: '/api/v1/payment-import/import_configurations',
    method: 'GET',
    params,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deleteImportConfigurations = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/payment-import/import_configurations/${id}`,
    method: 'DELETE',
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const editImportConfiguration = (id, data) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/payment-import/import_configurations/${id}`,
    method: 'PUT',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createImportConfiguration = data => {
  return request({
    url: '/api/v1/payment-import/import_configurations',
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getImportTypes = params => {
  return request({
    url: '/api/v1/payment-import/import_types',
    method: 'GET',
    params,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getImportConfiguration = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/payment-import/import_configurations/${id}`,
    method: 'GET',
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getActualImportConfigurations = params => {
  return request({
    url: '/api/v1/payment-import/import_configurations/actual',
    method: 'GET',
    params,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createImportPackage = data => {
  return request({
    url: '/api/v1/payment-imports/packages/create',
    method: 'POST',
    data,
  })
}

export const getImportPackages = (params?: unknown) => {
  return request<IResponseList<IPaymentImportPackage>>({
    url: `/api/v1/payment-import/packages`,
    method: 'GET',
    params,
  })
}

export const getPaymentImportPackageStatuses = (params?: IGetPackageStatusesQueryParams) => {
  return request<IResponseList<IPaymentImportPackage>>({
    url: `/api/v1/payment-import/package_statuses`,
    method: 'GET',
    params,
  })
}

export const getPackageDetails = (params?: ISearchPackageImportDetailsParams) => {
  return request<IResponseList<IPackageImportPackageDetails>>({
    url: `/api/v1/package_details/search`,
    method: 'GET',
    params,
  })
}

export const getRegistryConfig = () => {
  return Promise.resolve(paymentImportRegistryConfig)
}

export const reloadPaymentImportPackage = (packageId: string) => {
  return request({
    url: `/api/v1/payment_import/packages/${packageId}/reload`,
    method: 'PUT',
  })
}

export const deletePaymentImportPackage = (packageId: string) => {
  return request({
    url: `/api/v1/payment_import/packages/${packageId}`,
    method: 'DELETE',
  })
}
