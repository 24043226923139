import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUiStore = defineStore('ui', () => {
  // Theme
  const theme = ref('light')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  function setTheme(payload) {
    theme.value = payload
  }

  return {
    theme,
    setTheme,
  }
})
