<template>
  <n-space align="center">
    <n-popselect
      v-model:value="contourId"
      trigger="click"
      :options="uniqContours"
      label-field="contourName"
      @update:value="onChangeContour"
    >
      <n-button
        icon-placement="right"
        text
        color="#FFF"
      >
        <template #icon>
          <n-icon>
            <ChevronDownOutline />
          </n-icon>
        </template>
        {{ currentContour?.contourName }}
      </n-button>
    </n-popselect>
    <n-divider vertical />
    <n-popselect
      v-model:value="roleId"
      trigger="click"
      :options="mappedRoles"
      label-field="roleName"
      @update:value="onChangeRole"
    >
      <n-button
        icon-placement="right"
        text
        color="#FFF"
      >
        <template #icon>
          <n-icon>
            <ChevronDownOutline />
          </n-icon>
        </template>
        {{ currentRole?.roleName }}
      </n-button>
    </n-popselect>
  </n-space>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue'
import { uniqBy } from 'lodash'
import { useAuthStore } from '@/stores/auth'
import api from '@/api'
import { ChevronDownOutline } from '@vicons/ionicons5'
import { useRouter } from 'vue-router'
import { IContoursRoles } from '@/models/entities/contour'

const authStore = useAuthStore()
const router = useRouter()

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  fetchAvailableContoursRoles()

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  contourId.value = String(authStore.currentProfile.contourId)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  roleId.value = authStore.currentProfile.roleId
})

const contoursRoles = ref<IContoursRoles[]>([])
const contourId = ref<string | null>(null)
const roleId = ref<string | null>(null)

const fetchAvailableContoursRoles = async () => {
  try {
    const { list } = await api.users.getAvailableContourRoles()
    contoursRoles.value = list.map(contour => {
      const { contourId, ...data } = contour
      return { contourId: String(contourId), ...data }
    })
  } catch (e) {
    console.error(e)
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const onChangeContour = id => {
  if (currentContour.value) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setProfile(id, roleId.value)
  } else {
    const updatedContourRole = findContourRole(id)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    roleId.value = updatedContourRole.roleId

    // @ts-expect-error TODO refactoring
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setProfile(contourId.value, updatedContourRole.roleId)
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const onChangeRole = id => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises
  setProfile(contourId.value, id)
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const setProfile = async (contourId, roleId) => {
  if (!contourId || !roleId) return
  try {
    await api.auth.setProfile({ contourId, roleId })
    router.go(0)
  } catch (e) {
    console.error(e)
  }
}

const mappedContours = computed(() => {
  return contoursRoles.value.map(contour => {
    return {
      contourId: contour.contourId,
      contourName: contour.contourName,
      roleId: contour.roleId,
      value: contour.contourId,
    }
  })
})

const uniqContours = computed(() => {
  return uniqBy(mappedContours.value, 'contourId')
})

const currentContour = computed(() =>
  mappedContours.value.find(
    contour => contour.contourId === contourId.value && contour.roleId === roleId.value,
  ),
)

const currentContourRoles = computed(() =>
  contoursRoles.value.filter(contour => contour.contourId === contourId.value),
)

const mappedRoles = computed(() => {
  return uniqBy(currentContourRoles.value, 'roleId').map(contour => {
    return {
      roleName: contour.roleName,
      value: contour.roleId,
    }
  })
})

const currentRole = computed(() => mappedRoles.value.find(role => role.value === roleId.value))

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const findContourRole = contourId => {
  const contour = contoursRoles.value.find(contour => contour.contourId === contourId)
  return contour
}
</script>
