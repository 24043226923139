import { IUseobject } from '@/models/entities/useobject'
import { Ref } from 'vue'
import { IOptions } from '@/utils/mappers'

export function prepareObject(
  response: IUseobject,
  typesForSelect: Ref<IOptions[]>,
  classNames: Record<number, string>,
) {
  return {
    id: response.id,
    usecategory: response.usecategory,
    regionalCoefficient: '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    broadcastVolume: response.broadcastVolume,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    limitedAccess: response.limitedAccess,

    subject: response.subject,
    network: '-',
    groups: response.groups.map(el => el.name).join(', ') || '',
    contacts: '-',
    note: response.note,
    financialCharacteristics: '-',
    type: typesForSelect.value.find(el => el.value === response.typeId)?.label || '',
    class: Number(response.classId) || '',
    className: classNames[Number(response.classId)] || '',
    name: response.name || '',
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    fullAddress: response?.address?.fullAddress || '',
    address: response.address,
    parent: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      id: response?.parent?.id || '',
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      name: response?.parent?.name || '',
    },
    createdAt: new Date(response.createdAt).toLocaleDateString('ru-RU'),
    createdBy: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      id: response?.createdBy?.id || '',
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      name: response?.createdBy?.name || '',
    },
    geo:
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      response?.address?.geoLat && response?.address?.geoLon
        ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          `${response?.address?.geoLat}, ${response?.address?.geoLon}`
        : '',
    license: {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      number: response?.license?.number || '',
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      dateBegin: response?.license?.dateBeg
        ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          new Date(response?.license?.dateBeg.value).toLocaleDateString('ru-RU')
        : '',
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      dateEnd: response?.license?.dateEnd
        ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          new Date(response?.license?.dateEnd.value).toLocaleDateString('ru-RU')
        : '',
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      licenseTerritory: response?.license?.licenseTerritory || '',
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      volume: response?.license?.volume || '',
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      musicPercent: response?.license?.musicPercent || '',
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dateClose: response.dateClose,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    dateStart: response.dateStart,
  }
}
