import { IConfigElementSimple } from '@/models/preset'

export const licenseWorkTab: IConfigElementSimple = {
  name: 'Контейнер таба Работа с лицензией',
  type: 'ContainerPreset',
  widgetId: 'licenseRegistry_tabs_work_container',
  prefs: {
    label: null,
    hidden: 'false',
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'Кнопка Начать работу',
      type: 'ButtonPreset',
      widgetId: 'licenseRegistry_tabs_work_container_startWork_button',
      prefs: {
        label: 'Начать работу',
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Таблица таба Работа с лицензией',
      type: 'TablePreset',
      widgetId: 'licenseRegistry_tabs_work_table',
      prefs: {
        label: null,
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'колонка Дата начала',
          type: 'TableColumnPreset',
          widgetId: 'licenseWorkTab_column_startDate',
          prefs: {
            label: 'Дата начала',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Доход вещателя',
          type: 'TableColumnPreset',
          widgetId: 'licenseWorkTab_column_broadcasterIncome',
          prefs: {
            label: 'Доход вещателя',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Письмо вещателя',
          type: 'TableColumnPreset',
          widgetId: 'licenseWorkTab_column_docDate',
          prefs: {
            label: 'Письмо вещателя',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Запрос в РКН',
          type: 'TableColumnPreset',
          widgetId: 'licenseWorkTab_column_requestDate',
          prefs: {
            label: 'Запрос в РКН',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Ответ от РКН',
          type: 'TableColumnPreset',
          widgetId: 'licenseWorkTab_column_responseKindName',
          prefs: {
            label: 'Ответ от РКН',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Проект разработки',
          type: 'TableColumnPreset',
          widgetId: 'licenseWorkTab_column_withProject',
          prefs: {
            label: 'Проект разработки',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Статус',
          type: 'TableColumnPreset',
          widgetId: 'licenseWorkTab_column_workStatus',
          prefs: {
            label: 'Статус',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: '',
          type: 'TableColumnPreset',
          widgetId: 'licenseWorkTab_column_controls',
          prefs: {
            label: '',
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
      ],
    },
  ],
}
