import { defineStore } from 'pinia'
import { ref, type Ref } from 'vue'
import api from '@/api'
import { ISettingsParsed } from '@/models/entities/settings'

export const useUserSettingsStore = defineStore('settings', () => {
  const userSettings: Ref<ISettingsParsed | null> = ref<ISettingsParsed | null>(null)

  const isUserSettingsLoading = ref<boolean>(false)

  function setUserSettings(settings: ISettingsParsed | null) {
    userSettings.value = settings
  }

  function mergedSettings(newSettings: ISettingsParsed): ISettingsParsed {
    return {
      ...(userSettings.value || {}),
      ...newSettings,
    }
  }

  function fetchUserSettings(): Promise<void> {
    isUserSettingsLoading.value = true

    return (
      api.settings
        .getSettings()
        .then(response => {
          const settings =
            response.payload && typeof response.payload === 'string'
              ? JSON.parse(response.payload)
              : null

          setUserSettings(settings)
        })
        // eslint-disable-next-line @typescript-eslint/use-unknown-in-catch-callback-variable
        .catch(error => {
          console.error('Failed to fetch user settings:', error)
          setUserSettings(null)
        })
        .finally(() => {
          isUserSettingsLoading.value = false
        })
    )
  }

  function storeUserSettings(newSettings: ISettingsParsed) {
    setUserSettings(mergedSettings(newSettings))
  }

  function saveUserSettings(newSettings: ISettingsParsed): Promise<void> {
    return api.settings
      .setSettings({ payload: JSON.stringify(mergedSettings(newSettings)) })
      .then(() => {})
      .catch(() => {
        return fetchUserSettings()
      })
  }

  return {
    userSettings,
    isUserSettingsLoading,
    fetchUserSettings,
    saveUserSettings,
    storeUserSettings,
  }
})

export type UserSettingsStore = ReturnType<typeof useUserSettingsStore>
