// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const hexToHsl = hex => {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let r = parseInt(result[1], 16) / 255
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let g = parseInt(result[2], 16) / 255
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let b = parseInt(result[3], 16) / 255

  let max = Math.max(r, g, b)
  let min = Math.min(r, g, b)
  let h = (max + min) / 2
  let s = (max + min) / 2
  let l = (max + min) / 2
  if (max === min) {
    h = s = 0
  } else {
    let d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }
  s = s * 100
  s = Math.round(s)
  l = l * 100
  l = Math.round(l)
  h = Math.round(360 * h)
  return [h, s, l]
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const hslToHex = (h, s, l) => {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const f = n => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0')
  }
  return `#${f(0)}${f(8)}${f(4)}`
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const makeColorsPack = primary => {
  const hslPrimary = hexToHsl(primary)

  return {
    primaryColor: hslToHex(...hslPrimary),
    primaryColorHover: hslToHex(hslPrimary[0], hslPrimary[1] - 1, hslPrimary[2] - 4),
    primaryColorPressed: hslToHex(hslPrimary[0], hslPrimary[1] - 1, hslPrimary[2] - 6),
    primaryColorSuppl: hslToHex(hslPrimary[0], hslPrimary[1] - 1, hslPrimary[2] - 4),
    primaryColorHeader: hslToHex(hslPrimary[0], hslPrimary[1] - 1, hslPrimary[2] - 10),
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const globalRegister = pack => {
  console.log(pack)
  // console.log(hslToHex(...pack.primaryColorHover))
  document.documentElement.style.setProperty('--hg-primary-color', pack.primaryColor)
  document.documentElement.style.setProperty('--hg-primary-color-hover', pack.primaryColorHover)
  document.documentElement.style.setProperty('--hg-primary-color-header', pack.primaryColorHeader)
  document.documentElement.style.setProperty(
    '--hg-primary-color-table-hover',
    pack.primaryColorHover + '20',
  )
  document.documentElement.style.setProperty('--hg-primary-color-pressed', pack.primaryColorPressed)
}
