<template>
  <n-breadcrumb>
    <!-- @vue-ignore -->
    <n-breadcrumb-item
      v-for="item in breadcrumbStore.breadcrumb"
      :key="item.name"
      style="color: #fff"
      :clickable="Boolean(item.to)"
    >
      <!-- @vue-expect-error -->
      <router-link
        v-if="Boolean(item.to) && item.text"
        :to="item.to"
      >
        <span class="breadcrumb__text">
          <!-- @vue-expect-error -->
          {{ item.text }}
        </span>
      </router-link>
      <span
        v-else-if=" //@ts-ignore
          !Boolean(item.to) && item.text"
        class="breadcrumb__text"
      >
        <!-- @vue-expect-error -->
        {{ item.text }}
      </span>
      <n-skeleton
        v-else
        style="width: 100px"
        text
      />
    </n-breadcrumb-item>
  </n-breadcrumb>
</template>

<script setup>
import { computed, watch, onMounted } from 'vue'
import { useBreadcrumbStore } from '@/stores/breadcrumb'
import { useRoute } from 'vue-router'

const route = useRoute()

const breadcrumbStore = useBreadcrumbStore()

const breadcrumbMeta = computed(() => route.meta.breadcrumb || [])

onMounted(() => {
  breadcrumbStore.buildBreadcrumb(breadcrumbMeta.value || [])
})

watch(breadcrumbMeta, config => {
  breadcrumbStore.buildBreadcrumb(config || [])
})
</script>

<style lang="scss">
.n-breadcrumb {
  .n-breadcrumb-item {
    .n-breadcrumb-item {
      &__separator {
        color: #fff;
      }

      &__link {
        color: #fff;

        &:hover {
          color: #fff;
        }
      }
    }
  }

  .n-breadcrumb-item:last-child {
    .n-breadcrumb-item {
      &__link {
        color: #fff;
      }
    }
  }
}

.breadcrumb__text {
  display: block;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
