import { IConfigElementSimple } from '@/models/preset'

export const licenseDifferenceTable: IConfigElementSimple = {
  name: 'Таблица Версий лицензий',
  type: 'TablePreset',
  widgetId: 'licenseRegistry_tabs_differences_versions',
  prefs: {
    label: null,
    hidden: 'false',
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'колонка Параметр',
      type: 'TableColumnPreset',
      widgetId: 'licenseRegistry_tabs_differences_versions_column_param',
      prefs: {
        label: 'Параметр',
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка До',
      type: 'TableColumnPreset',
      widgetId: 'licenseRegistry_tabs_differences_versions_column_before',
      prefs: {
        label: 'До',
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'колонка После',
      type: 'TableColumnPreset',
      widgetId: 'licenseRegistry_tabs_differences_versions_column_after',
      prefs: {
        label: 'После',
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [],
    },
  ],
}
