import { IConfigElementSimple } from '@/models/preset'

export const paymentImportRegistryConfig: IConfigElementSimple = {
  name: 'Реестр загрузок поступлений',
  type: 'RegistryPage',
  widgetId: 'paymentImportPackagesRegistry',
  prefs: {
    label: null,
    hidden: null,
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'Кнопка Группы',
      type: 'ButtonPreset',
      widgetId: 'departments_registry_controls_groups',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [],
    },
  ],
}
