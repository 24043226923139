import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import resize from '@/utils/directives/resize'
import columnResize from '@/utils/directives/columnResize'
import mask from '@/utils/directives/mask'

import { importPresets } from '@/utils/presetService'

import { createPinia } from 'pinia'

const pinia = createPinia()
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VResizable from 'v-resizable'

const app = createApp(App).use(router).use(Toast).use(pinia).use(VResizable)
app.directive('resize', resize)
app.directive('column-resize', columnResize)
app.directive('mask', mask)

// eslint-disable-next-line @typescript-eslint/no-floating-promises
importPresets().then(function () {
  app.mount('#app')
})
