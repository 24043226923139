<template>
  <n-layout-sider
    bordered
    show-trigger
    collapse-mode="width"
    :collapsed-width="50"
    :default-collapsed="false"
  >
    <n-menu
      :collapsed-icon-size="22"
      :options="menuItems"
      :value="route.name"
    />
  </n-layout-sider>
</template>

<script setup lang="ts">
import { h, computed } from 'vue'
import { NIcon } from 'naive-ui'
import * as icons from '@vicons/ionicons5'
import { RouterLink, useRoute } from 'vue-router'
import sideMenuConfig from './config/sideMenu.frontconf'
import { CONFIG_ELEMENT_TYPES, IConfigElement } from '@/models/preset'

const route = useRoute()

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const renderIcon = (icon: string) => () => h(NIcon, null, { default: () => h(icons[icon]) })

const menuItems = computed(() =>
  buildMenu(sideMenuConfig.children as IConfigElement<CONFIG_ELEMENT_TYPES.SideMenuItemPreset>[]),
)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const buildMenuItem = (config: IConfigElement<CONFIG_ELEMENT_TYPES.SideMenuItemPreset>) => ({
  label: () => {
    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
    if (config.children?.length || !config.prefs?.options?.navigateTo) {
      /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
      return config.prefs?.label
    }

    return h(
      RouterLink,
      { to: config.prefs.options.navigateTo },
      {
        /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
        default: () => config.prefs?.label,
      },
    )
  },
  /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
  ...(config.prefs?.options?.icon ? { icon: renderIcon(config.prefs.options.icon) } : {}),
  /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
  ...(config.children?.length
    ? {
        children: buildMenu(
          config.children as IConfigElement<CONFIG_ELEMENT_TYPES.SideMenuItemPreset>[],
        ),
      }
    : {}),
  key:
    /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
    typeof config.prefs?.options?.navigateTo === 'string'
      ? /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
        config.prefs?.options?.navigateTo
      : /* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */
        config.prefs?.options?.navigateTo?.name || config.prefs.label,
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const buildMenu = (rawMenuItems: IConfigElement<CONFIG_ELEMENT_TYPES.SideMenuItemPreset>[]) =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  rawMenuItems.map(rawMenuItem => buildMenuItem(rawMenuItem))
</script>
