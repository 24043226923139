import { ref } from 'vue'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function useLocalStorage(storageKey) {
  let valueProxy = ref(localStorage.getItem(storageKey))

  window.addEventListener('storage', ({ key, newValue }) => {
    if (key !== storageKey) {
      return
    }
    valueProxy.value = newValue
  })

  return valueProxy
}
