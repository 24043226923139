import * as auth from './auth'
import * as contour from './contour'
import * as subjects from './subjects'
import * as address from './address'
import * as contract from './contract'
import * as bank from './bank'
import * as bankRegistry from './bankRegistry'
import * as bankAccounts from './bankAccounts'
import * as payment from './payment'
import * as paymentImport from './paymentImport'
import * as users from './users'
import * as dictionary from './dictionary'
import * as file from './file'
import * as form from './form'
import * as translation from './translation'
import * as settings from './settings'
import * as usecategory from './usecategory'
import * as useobject from './useobject/index'
import * as department from './department'
import * as document from './document'
import * as documentTypes from './documentTypes'
import * as identificationConfigurations from './identificationConfigurations'
import * as positions from './positions'
import * as positionTypes from './positionTypes'
import * as contact from './contact'
import * as license from './license'
import * as presetConfig from './presetConfig'
import * as userDevelopment from './userDevelopment'
import * as netSourceType from './netSourceType'
import * as netSource from './netSource'
import * as financialParameters from './financialParameters'
import * as widgetConfig from './widgetConfig'

export default {
  auth,
  contour,
  subjects,
  address,
  contract,
  bank,
  bankRegistry,
  bankAccounts,
  payment,
  paymentImport,
  users,
  dictionary,
  file,
  form,
  translation,
  settings,
  usecategory,
  useobject,
  department,
  document,
  documentTypes,
  identificationConfigurations,
  positions,
  positionTypes,
  contact,
  license,
  presetConfig,
  userDevelopment,
  netSourceType,
  netSource,
  financialParameters,
  widgetConfig,
}
