import { POSITION, useToast } from 'vue-toastification'
import { h } from 'vue'

interface INotification {
  type: 'success' | 'error' | 'warning'
  message: string | typeof h
  position?: POSITION
}

export function useNotification(params: INotification) {
  try {
    const options: { timeout: number; position: INotification['position'] } = {
      timeout: 4000,
      position: params.position || getDefaultPosition(params.type),
    }
    return useToast()[params.type](params.message, options)
  } catch (e) {
    console.error(e)
  }
}

export function showSuccessfulMsg(message: string) {
  useNotification({
    type: 'success',
    message: message,
  })
}

function getDefaultPosition(notificationType: INotification['type']): POSITION {
  if (notificationType === 'error') {
    return POSITION.TOP_RIGHT
  }
  return POSITION.TOP_CENTER
}
