import {
  DEFAULT_MAX_WIDTH,
  DEFAULT_MIN_WIDTH,
} from '@/components/presets/table/helpers/getDefaultColumnConfig'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getSumColsWidth = cols => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return cols.reduce((sum, c) => {
    return sum + (c.calculatedStyle.width || 0)
  }, 0)
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const setColElementWidth = (col, secondCols) => {
  col.element.style.width = `${col.calculatedStyle.width}px`
  if (secondCols && secondCols.length) {
    secondCols[col.index].element.style.width = `${col.calculatedStyle.width}px`
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const setNewColCalculatedWidth = (col, secondCols, newColWidth) => {
  col.calculatedStyle.width = newColWidth
  if (secondCols && secondCols.length) {
    secondCols[col.index].calculatedStyle.width = newColWidth
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const limitWidth = (width, minWidth, maxWidth) => {
  if (!width) {
    return minWidth
  }

  let newWidth = width
  if (minWidth) {
    newWidth = Math.max(width, minWidth)
  }
  if (maxWidth) {
    newWidth = Math.min(newWidth, maxWidth)
  }
  return newWidth
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const getColsWithoutWidthCount = cols => cols.filter(c => !c.calculatedStyle.width)?.length

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const calculateJustifyStretchColWidth = (cols, scrollX, justifyStretch) => {
  if (!justifyStretch) return

  const colsWithoutWidthCount = getColsWithoutWidthCount(cols)
  if (!colsWithoutWidthCount) return

  const sumColsWidth = getSumColsWidth(cols)
  return (scrollX - sumColsWidth) / colsWithoutWidthCount
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const prepareDefaultCalculatedStyle = (col, cols, colJustifyStretchWidth) => {
  const { width, minWidth, maxWidth } = col.calculatedStyle
  const colStyle = {
    width: 0,
    minWidth: minWidth || DEFAULT_MIN_WIDTH,
    maxWidth: maxWidth || DEFAULT_MAX_WIDTH,
  }

  if (!width && colJustifyStretchWidth) {
    colStyle.width = colJustifyStretchWidth
  } else {
    colStyle.width = limitWidth(width, colStyle.minWidth, colStyle.maxWidth)

    if (col.index === cols.length - 1) {
      colStyle.width = limitWidth(width, colStyle.minWidth)
    }
  }
  return colStyle
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const setDefaultColsWidth = ({ cols, secondCols, scrollX, justifyStretch }) => {
  const colJustifyStretchWidth = calculateJustifyStretchColWidth(cols, scrollX, justifyStretch)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cols.forEach((col, index) => {
    if (!col.element) return

    const defaultCalculatedStyle = prepareDefaultCalculatedStyle(col, cols, colJustifyStretchWidth)

    Object.assign(col.calculatedStyle, defaultCalculatedStyle)
    if (secondCols && secondCols.length) {
      Object.assign(secondCols[index].calculatedStyle, defaultCalculatedStyle)
    }
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const setLastColWidth = ({ cols, secondCols, remainingWidth }) => {
  const lastColWidth = cols[cols.length - 1].calculatedStyle.width + Math.floor(remainingWidth)

  setNewColCalculatedWidth(cols[cols.length - 1], secondCols, lastColWidth)
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const setColsMinimalWidth = ({ cols, secondCols }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cols.forEach(col => {
    setNewColCalculatedWidth(col, secondCols, col.calculatedStyle.minWidth)
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const limitAllColsWidth = ({ cols, secondCols }) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  cols.forEach(col => {
    const { width, minWidth, maxWidth } = col.calculatedStyle
    const newColWidth = limitWidth(width, minWidth, maxWidth)

    setNewColCalculatedWidth(col, secondCols, newColWidth)
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const canDecreaseWidth = (col, movement = 0) => {
  if (!col) return false

  return col.calculatedStyle.width - movement >= col.calculatedStyle.minWidth
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const canIncreaseWidth = (col, movement = 0) => {
  if (!col) return false

  return col.calculatedStyle.width + movement <= col.calculatedStyle.maxWidth
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const minifyCols = ({ cols, secondCols, scrollX }) => {
  limitAllColsWidth({ cols, secondCols })

  const newSumColsWidth = getSumColsWidth(cols)
  if (newSumColsWidth - scrollX > 0) {
    setColsMinimalWidth({
      cols,
      secondCols,
    })
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const initColMapping = (el, selector) => {
  const columns = el.querySelectorAll(selector)
  return Array.from(columns).map((col, index) => ({
    index,
    element: col,
    calculatedStyle: {
      width: parseInt(col.style.width) || DEFAULT_MIN_WIDTH,
      minWidth: parseInt(col.style.minWidth) || DEFAULT_MIN_WIDTH,
      maxWidth: parseInt(col.style.maxWidth) || DEFAULT_MAX_WIDTH,
    },
  }))
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const initResizeBar = th => {
  //класс коробочной кнопки ресайза naive-ui
  const barClassName = 'n-data-table-resize-button'
  const previousBar = th.querySelector(`.${barClassName}`)
  if (previousBar) {
    previousBar.remove()
  }

  const bar = document.createElement('span')
  bar.className = barClassName
  bar.dataset.dataTableResizable = 'true'

  return bar
}
