import request, { IResponseList, IResponseEntity } from '@/api/request'
import {
  IGetTranslationsParams,
  ITokenTranslations,
  ILanguage,
  ITokenTranslationsCreate,
  ITokenTranslation,
} from '@/models/entities/translations'

export const getLanguages = (params?: unknown) => {
  return request<IResponseList<ILanguage>>({
    url: '/api/v1/translation/languages',
    method: 'GET',
    params,
  })
}

export const getTranslationsDb = (params: unknown) => {
  return request({
    url: '/api/v1/translation/translations-db',
    method: 'GET',
    params,
  })
}

export const getTranslationsSearch = (params?: IGetTranslationsParams) => {
  return request<IResponseList<ITokenTranslations>>({
    url: '/api/v1/translation/tokens/search',
    method: 'GET',
    params,
  })
}

export const getTranslations = (params: unknown) => {
  return request({
    url: '/api/v1/translation/translations',
    method: 'GET',
    params,
  })
}

export const createOrUpdateTranslation = (data: ITokenTranslationsCreate) => {
  return request<IResponseEntity<ITokenTranslations>>({
    url: '/api/v1/translations/update_or_create',
    method: 'POST',
    data,
  })
}

export const deleteTranslation = (id: ITokenTranslation['id']) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/translations/translation/${id}`,
    method: 'DELETE',
    data: null,
  })
}

export const getTranslationsUntranslated = (params: unknown) => {
  return request({
    url: '/api/v1/translation/translations/untranslated',
    method: 'GET',
    params,
  })
}

export const getTokens = () => {
  return request({
    url: '/api/v1/translation/tokens',
    method: 'GET',
  })
}

export const createToken = (data: unknown) => {
  return request({
    url: '/api/v1/translation/tokens',
    method: 'POST',
    data,
  })
}

export const updateToken = (data: unknown, id: string | number) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/translation/tokens/${id}`,
    method: 'PUT',
    data,
  })
}
