// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function resize(el, binding, vnode) {
  const clickOffset = 20
  let elBounds = el.getBoundingClientRect()
  let vnodeBounds = vnode.el.getBoundingClientRect()
  const direction = binding.value
  el.addEventListener(
    'mousedown',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mousedownEvent => {
      let resizeListener = null
      elBounds = el.getBoundingClientRect()
      if (mousedownEvent.offsetX + clickOffset >= elBounds.width && direction === 'right') {
        el.style.cursor = 'col-resize'
        resizeListener = doResize('right')
      }
      if (mousedownEvent.offsetY + clickOffset >= elBounds.heigth && direction === 'bottom') {
        el.style.cursor = 'row-resize'
        resizeListener = doResize('bottom')
      }
      if (resizeListener) {
        window.addEventListener('mousemove', resizeListener, false)
        window.addEventListener(
          'mouseup',
          () => {
            window.removeEventListener('mousemove', resizeListener, false)
            el.style.cursor = 'auto'
          },
          false,
        )
      }
    },
    false,
  )

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const doResize = direction => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return event => {
      if (direction === 'right') {
        el.style.width = `${event.x - vnodeBounds.left + clickOffset}px`
        el.style.minWidth = `${event.x - vnodeBounds.left + clickOffset}px`
        elBounds = el.getBoundingClientRect()
      }
      if (direction === 'bottom') {
        el.style.height = `${event.offsetY + 5}px`
      }
    }
  }
}
