import axios, { AxiosRequestConfig } from 'axios'
import qs from 'qs'
import { useNotification } from '@/composables/useNotification'
import router from '@/router'
import { useTranslationStore } from '@/stores/translation'
import { Nullable } from '@/models/helpers'
import { useAuthStore } from '@/stores/auth'
import { LOGIN_ROUTE_NAME } from '@/router/constants'

export interface IResponseList<T> {
  list: T[]
  count: number
  filters: Nullable<Record<string, unknown>>
}

export interface IAggregation {
  items: IAggregationItem[]
}

export interface IAggregationItem {
  key: string
  value: string
  count: string
}
export interface IResponseListWithAggregations<T> extends IResponseList<T> {
  aggregations?: {
    aggregations: Record<string, IAggregation>
  }
}

export interface IResponseEntity<T> {
  data: T
}

const showResponseErrorMessage = (message: string | string[] = 'Unknown server error') => {
  const translationStore = useTranslationStore()
  const translatedMessage = Array.isArray(message)
    ? message.map(msg => translationStore.translateString(msg)).join('\n')
    : translationStore.translateString(message)

  useNotification({
    type: 'error',
    message: translatedMessage,
  })
}

axios.interceptors.response.use(
  response => {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!response) return {}
    return response
  },
  error => {
    if (error.response?.status === 401) {
      const authStore = useAuthStore()
      authStore.setIsAuth(false)

      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push({ name: LOGIN_ROUTE_NAME })
      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      return Promise.reject(error)
    }
    if (!error.request?.responseURL?.includes('/api/v1/form/elements/available_elements')) {
      showResponseErrorMessage(error?.response?.data?.message)
    }
    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
    return Promise.reject(error)
  },
)

export default function request<T = unknown>({
  method = 'GET',
  url,
  data = {},
  params = {},
  headers = {},
  responseType = 'json',
}: AxiosRequestConfig): Promise<T> {
  return axios({
    method,
    url,
    data,
    headers,
    params,
    responseType,
    withCredentials: true,
    paramsSerializer(params) {
      return qs.stringify(params, { arrayFormat: 'repeat', encode: false })
    },
  }).then(response => response.data)
}
