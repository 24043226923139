import {
  canDecreaseWidth,
  canIncreaseWidth,
  getSumColsWidth,
  initColMapping,
  initResizeBar,
  minifyCols,
  setColElementWidth,
  setDefaultColsWidth,
  setLastColWidth,
  setNewColCalculatedWidth,
} from '@/utils/directives/columnResize/helpers'
import { throttle } from 'lodash'

const COLUMN_RESIZING_THRESHOLD = 20
const THROTTLE_WAIT = 100

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default function columnResize(el, binding, vnode) {
  if (!binding.value.resizable) return

  /**
   * В data-table Naive-ui, если у таблицы есть maxHeight или flexHeight, то заголовки находятся в отдельной таблице.
   * В этом случае требуется при ресайзе изменять width сразу в 2 таблицах для соответствующих столбцов.
   * @type {boolean}
   */
  const headerInBody =
    typeof vnode.ctx?.props?.maxHeight === 'undefined' && !vnode.ctx?.props?.flexHeight

  const colsInHeaderSelector = '.n-data-table-base-table-header .n-data-table-table colgroup col'
  const colsInBodySelector = '.n-data-table-base-table-body .n-data-table-table colgroup col'
  const mainColsSelector = !headerInBody ? colsInHeaderSelector : colsInBodySelector

  const secondCols = !headerInBody ? initColMapping(el, colsInBodySelector) : null
  const cols = initColMapping(el, mainColsSelector)

  if (!cols.length) return

  const tableEl = el.querySelector('.n-data-table-table')
  const tableWidth = el.getBoundingClientRect()?.width

  const scrollBarContentEl = el.querySelector('.n-scrollbar-content')
  const scrollX = vnode.ctx?.props?.scrollX || tableWidth

  let newScrollContainerWidth = scrollX

  const { onResizeEnd, stretch: justifyStretch, throttle: repaintThrottle } = binding.value

  //устанавливаем ограничение ресайза таблицы её 100% шириной
  const tableWrapperEl = el.querySelector('.n-data-table-wrapper')
  if (tableWrapperEl) {
    tableWrapperEl.style.width = 'fit-content'
    tableWrapperEl.style.maxWidth = '100%'
  }

  const fireResizeEndEvent = () => {
    const resizedCols = cols.map((col, index) => {
      const { key, widgetId } = vnode.ctx?.props?.columns[index] || {}
      return {
        key,
        widgetId,
        width: col.element.style.width,
        minWidth: col.element.style.minWidth,
      }
    })
    onResizeEnd(resizedCols)
  }

  const repaint = () => {
    cols.forEach(col => {
      setColElementWidth(col, secondCols)
    })

    if (scrollBarContentEl) {
      scrollBarContentEl.style.minWidth = `${newScrollContainerWidth}px`
    }
    tableEl.style.minWidth = `${newScrollContainerWidth}px`
  }

  const repairTable = (iteration = 0) => {
    if (iteration > 3) {
      console.error('Не удается автоматически исправить ширину столбцов таблицы.')
      return
    }
    iteration++

    //на всякий случай проверяем, установлены ли значения ширины, если нет, устанавливаем по умолчанию
    const colsSumWidth = getSumColsWidth(cols)
    setDefaultColsWidth({
      cols,
      secondCols,
      scrollX,
      justifyStretch,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      colsSumWidth,
    })

    if (colsSumWidth < newScrollContainerWidth) {
      if (colsSumWidth >= tableWidth) {
        newScrollContainerWidth = colsSumWidth
      } else {
        newScrollContainerWidth = tableWidth

        setLastColWidth({
          cols,
          secondCols,
          remainingWidth: tableWidth - colsSumWidth,
        })
      }
    } else if (colsSumWidth - newScrollContainerWidth > COLUMN_RESIZING_THRESHOLD) {
      minifyCols({ cols, secondCols, scrollX })
      repairTable(iteration)
    }
    repaint()
  }

  //сразу запускаем восстановление таблицы для начального выравнивания
  repairTable()

  if (el.querySelector('.n-data-table-empty')) return

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let currentCol = null
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  let nextNeighbourCol = null

  //находим все th таблицы для подстановки в них кнопок ресайза
  const ths = el.querySelectorAll('.n-data-table-thead th')

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ths.forEach((th, index) => {
    const bar = initResizeBar(th)

    bar.addEventListener('mousedown', () => {
      currentCol = cols.find(col => col.index === index)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      nextNeighbourCol = cols[currentCol.index + 1]

      window.addEventListener('mousemove', handleResize)
      window.addEventListener('mouseup', onMouseUp)
    })
    th.append(bar)
  })

  const onMouseUp = () => {
    window.removeEventListener('mousemove', handleResize)
    window.removeEventListener('mouseup', onMouseUp)

    repairTable()

    if (typeof onResizeEnd === 'function') {
      fireResizeEndEvent()
    }
  }

  const resizeRepaint = repaintThrottle ? throttle(repaint, THROTTLE_WAIT) : repaint

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleResize = e => {
    if (e.movementX === 0) return

    if (e.movementX > 0) {
      handleIncreaseWidth(Math.abs(e.movementX))
    } else if (e.movementX < 0) {
      handleDecreaseWidth(Math.abs(e.movementX))
    }

    resizeRepaint()
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleIncreaseWidth = movement => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!canIncreaseWidth(currentCol, movement)) {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (shouldDecreaseLastCol(movement)) {
      decreaseWidth(cols[cols.length - 1], movement)
    } else if (canIncreaseScrollX(movement)) {
      increaseScrollX(movement)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (canDecreaseWidth(nextNeighbourCol, movement)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      decreaseWidth(nextNeighbourCol, movement)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (nextNeighbourCol.index !== cols.length - 1) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      nextNeighbourCol = cols[nextNeighbourCol.index + 1]
      return
    } else {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    increaseWidth(currentCol, movement)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleDecreaseWidth = movement => {
    //сбрасываем следующий столбец, если уменьшали столбцы не отжимая мышку
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    nextNeighbourCol = cols[currentCol.index + 1]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!canDecreaseWidth(currentCol, movement)) {
      return
    }

    if (canDecreaseScrollX(movement)) {
      decreaseScrollX(movement)
    } else if (canIncreaseWidth(nextNeighbourCol, movement)) {
      increaseWidth(nextNeighbourCol, movement)
    } else {
      return
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    decreaseWidth(currentCol, movement)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const canIncreaseScrollX = movement => {
    return newScrollContainerWidth + movement < scrollX
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const canDecreaseScrollX = movement => {
    return newScrollContainerWidth - movement >= Math.floor(tableWidth)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const increaseScrollX = movement => (newScrollContainerWidth = newScrollContainerWidth + movement)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const decreaseScrollX = movement => (newScrollContainerWidth = newScrollContainerWidth - movement)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const increaseWidth = (col, movement = 0) => {
    const newColWidth = col.calculatedStyle.width + movement
    setNewColCalculatedWidth(col, secondCols, newColWidth)
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const decreaseWidth = (col, movement = 0) => {
    const newColWidth = col.calculatedStyle.width - movement
    setNewColCalculatedWidth(col, secondCols, newColWidth)
  }

  const shouldDecreaseLastCol = () => {
    const { width, maxWidth } = cols[cols.length - 1].calculatedStyle
    return width > maxWidth
  }
}
