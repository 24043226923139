import request, { IResponseEntity, IResponseList } from '@/api/request'
import {
  IEditStageData,
  IPayloadCreateStageData,
  IStage,
  IStageSettings,
  IStageDetailed,
  IStageWarningEdit,
  IStageFile,
  IStageDetailedWarnings,
} from '@/models/entities/stage'
import {
  IConflictUseobject,
  IConflictUseobjectSearch,
  ICreateConflictUseobjectData,
} from '@/models/entities/conflictUseobject'
import {
  IConflictCase,
  IConflictCaseDetailed,
  IConflictCaseType,
  ICreateConflictCaseData,
  ICreateConflictCaseFileData,
  IEditConflictCaseData,
  IEditConflictCaseStatusData,
  IGetConflictCasesParams,
} from '@/models/entities/conflictCases'

import {
  IWayToFixation,
  IReasonForFailure,
  INecessaryControlKind,
  IPower,
  IMonitorReason,
  IPrintFileData,
  IPrintFile,
  IFileDetailed,
  ICreateFileData,
  IGetFilesDetailedParams,
} from '@/models/entities/userDevelopment'
import {
  IGetCompositionParams,
  IComposition,
  ICreateClaimCompositions,
  IClaim,
  IGetClaimsParams,
  IGetClaimDetailsParams,
  IClaimCalculateData,
  IEditClaimData,
  IClaimDetails,
} from '@/models/entities/claim'
import {
  ICreateFixationData,
  IEditFixationData,
  IFixation,
  IFixationSearch,
  IFixationStatuses,
} from '@/models/entities/fixation'

export const getStageSettings = (params: unknown) => {
  return request<IResponseList<IStageSettings>>({
    url: `/api/v1/user_development/stage_settings/list`,
    method: 'GET',
    params,
  })
}

export const getStages = (params: unknown) => {
  return request<IResponseList<IStageDetailed>>({
    url: `/api/v1/user_development/stages`,
    method: 'GET',
    params,
  })
}

export const getStage = (id: IStage['id']) => {
  return request<IResponseEntity<IStageDetailed>>({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'GET',
  })
}

export const createStage = (data: IPayloadCreateStageData) => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/stages`,
    method: 'POST',
    data,
  })
}

export const editStage = (id: IStage['id'], data: IEditStageData) => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteStage = (id: IStage['id']) => {
  return request({
    url: `/api/v1/user_development/stages/${id}`,
    method: 'DELETE',
  })
}

export const getStageFiles = (params?: unknown) => {
  return request<IResponseList<IStageFile>>({
    url: `/api/v1/user_development/files/detailed`,
    method: 'GET',
    params,
  })
}

export const getConflictUseobjects = (params: unknown) => {
  return request<IResponseList<IConflictUseobject>>({
    url: `/api/v1/user_development/conflict_useobjects`,
    method: 'GET',
    params,
  })
}

export const searchConflictUseobjects = (params: unknown) => {
  return request<IResponseList<IConflictUseobjectSearch>>({
    url: `/api/v1/user_development/conflict_useobjects/search`,
    method: 'GET',
    params,
  })
}

export const getConflictUseobject = (id: IConflictUseobject['id']) => {
  return request<IResponseEntity<IConflictUseobject>>({
    url: `/api/v1/user_development/conflict_useobjects/${id}`,
    method: 'GET',
  })
}

export const searchConflictUseobjectStatuses = (params?: unknown) => {
  return request<IResponseList<unknown>>({
    url: `/api/v1/user_development/conflict_useobject/statuses/detailed`,
    params,
  })
}

export const createConflictUseobject = (data: ICreateConflictUseobjectData) => {
  return request({
    url: `/api/v1/user_development/conflict_useobjects`,
    method: 'POST',
    data,
  })
}

export const deleteConflictUseobject = (id: string) => {
  return request({
    url: `/api/v1/user_development/conflict_useobjects/${id}`,
    method: 'DELETE',
  })
}

export const deleteUserDevelopmentFile = (id: string) => {
  return request({
    url: `/api/v1/user_development/files/${id}/delete`,
    method: 'DELETE',
  })
}

export const getFixations = (params: unknown) => {
  return request<IResponseList<IFixationSearch>>({
    url: `/api/v1/user_development/fixations/search`,
    method: 'GET',
    params,
  })
}

export const getFixation = (id: IFixation['id']) => {
  return request<IResponseEntity<IFixation>>({
    url: `/api/v1/user_development/fixations/${id}`,
    method: 'GET',
  })
}

export const getConflictCase = (id: string | number) => {
  return request<IResponseEntity<IConflictCaseDetailed>>({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/user_development/id/conflict_cases?id=${id}`,
    method: 'GET',
  })
}

export const editWarning = (id: IStageDetailedWarnings['id'], data: IStageWarningEdit) => {
  return request<IResponseEntity<IStageDetailedWarnings>>({
    url: `/api/v1/user_development/warnings/${id}`,
    method: 'PUT',
    data,
  })
}

export const createConflictCase = (data: ICreateConflictCaseData) => {
  return request({
    url: `/api/v1/user_development/conflict_cases/create`,
    method: 'POST',
    data,
  })
}

export const editConflictCase = (id: IConflictCase['id'], data: IEditConflictCaseData) => {
  return request<IResponseEntity<IConflictCaseDetailed>>({
    url: `/api/v1/user_development/conflict_cases/${id}/update`,
    method: 'PUT',
    data,
  })
}

export const editConflictCaseStatus = (
  id: IConflictCase['id'],
  data: IEditConflictCaseStatusData,
) => {
  return request<IResponseEntity<IConflictCaseDetailed>>({
    url: `/api/v1/user_development/conflict_cases/${id}/status_update`,
    method: 'POST',
    data,
  })
}

export const getConflictCasesSearch = (params?: IGetConflictCasesParams) => {
  return request<IResponseList<IConflictCase>>({
    url: `/api/v1/user_development/conflict_cases/search`,
    method: 'GET',
    params,
  })
}

export const getConflictCaseTypes = (params?: unknown) => {
  return request<IResponseList<IConflictCaseType>>({
    url: `/api/v1/user_development/conflict_cases/types`,
    params,
  })
}

export const getConflictCaseStatuses = (params?: unknown) => {
  return request<IResponseList<IConflictCase['status']>>({
    url: `/api/v1/user_development/conflict_cases/statuses`,
    params,
  })
}

export const createFixation = (data: ICreateFixationData) => {
  return request({
    url: `/api/v1/user_development/fixations/create`,
    method: 'POST',
    data,
  })
}

export const getFixationStatuses = (params: unknown) => {
  return request<IResponseList<IFixationStatuses>>({
    url: '/api/v1/user_development/fixations/statuses',
    method: 'GET',
    params,
  })
}

export const editFixation = (id: IFixation['id'], data: IEditFixationData) => {
  return request<IResponseEntity<IStage>>({
    url: `/api/v1/user_development/fixations/${id}/update`,
    method: 'PUT',
    data,
  })
}

export const getReasonsForFailure = (params: unknown) => {
  return request<IResponseList<IReasonForFailure>>({
    url: `/api/v1/user_development/reasons_for_failures`,
    method: 'GET',
    params,
  })
}

export const getNecessaryControlKinds = (params: unknown) => {
  return request<IResponseList<INecessaryControlKind>>({
    url: `/api/v1/user_development/necessary_control_kinds`,
    method: 'GET',
    params,
  })
}

export const getWaysToFixations = (params: unknown) => {
  return request<IResponseList<IWayToFixation>>({
    url: `/api/v1/user_development/ways_to_fixations`,
    method: 'GET',
    params,
  })
}

export const printFile = (data: Partial<IPrintFileData>) => {
  return request<IPrintFile>({
    url: `/api/v1/user_development/print`,
    method: 'PUT',
    data,
  })
}

export const createFile = (data: ICreateFileData) => {
  return request({
    url: `/api/v1/user_development/files/create`,
    method: 'POST',
    data,
  })
}

export const loadConflictCaseFile = (data: ICreateConflictCaseFileData) => {
  return request({
    url: `/api/v1/user_development/load`,
    method: 'POST',
    data,
  })
}

export const getFilesDetailed = (params: Partial<IGetFilesDetailedParams>) => {
  return request<IResponseList<IFileDetailed>>({
    url: `/api/v1/user_development/files/detailed`,
    method: 'GET',
    params,
  })
}

export const getCompositions = (params: Partial<IGetCompositionParams>) => {
  return request<IResponseList<IComposition>>({
    url: `/api/v1/user_development/compositions`,
    method: 'GET',
    params,
  })
}

export const getClaims = (params: Partial<IGetClaimsParams>) => {
  return request<IResponseList<IClaim>>({
    url: `/api/v1/user_development/claims`,
    method: 'GET',
    params,
  })
}

export const getClaimDetails = (params: Partial<IGetClaimDetailsParams>) => {
  return request<IResponseList<IClaimDetails>>({
    url: `/api/v1/user_development/claims/details`,
    method: 'GET',
    params,
  })
}

export const editClaim = (id: IClaim['id'], data: IEditClaimData) => {
  return request({
    url: `/api/v1/user_development/claims/${id}/update`,
    method: 'PUT',
    data,
  })
}

export const calculateClaims = (data: IClaimCalculateData) => {
  return request<IResponseEntity<IClaimDetails>>({
    url: `/api/v1/user_development/claims/calculate`,
    method: 'PUT',
    data,
  })
}

export const createClaimCompositions = (data: ICreateClaimCompositions) => {
  return request({
    url: `/api/v1/user_development/claim_compositions/add`,
    method: 'POST',
    data,
  })
}

export const getPowers = (params: unknown) => {
  return request<IResponseList<IPower>>({
    url: `/api/v1/user_development/powers`,
    method: 'GET',
    params,
  })
}

export const getMonitorReasons = (params: unknown) => {
  return request<IResponseList<IMonitorReason>>({
    url: `/api/v1/user_development/monitor_reasons`,
    method: 'GET',
    params,
  })
}
