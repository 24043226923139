import { get } from 'lodash'
import { changeNumericValueType } from '@/utils/baseHelpers'
export interface IOptions {
  label: string
  value: string | number
}

interface IMapperOptions {
  isConvertIdToNumber?: boolean
}

export function mapOptions(
  list: unknown[] = [],
  labelMapKey: string = 'name',
  labelMapValue: string = 'id',
  mapperOptions?: IMapperOptions,
): IOptions[] {
  let isConvertIdToNumber = mapperOptions?.isConvertIdToNumber
  return list.map(item => {
    const label = get(item, labelMapKey)
    const value = get(item, labelMapValue)

    return {
      label,
      value: isConvertIdToNumber ? changeNumericValueType(value) : value,
    }
  })
}

export function mapOptionsWithDescription(
  list: unknown[] = [],
  labelMapKeys: unknown[] = [],
  mapperOptions?: IMapperOptions,
) {
  let isConvertIdToNumber = mapperOptions?.isConvertIdToNumber
  return list.map(item => {
    const [name, description] = labelMapKeys
    return {
      // @ts-expect-error TODO refactoring
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      label: `${item[name] || '-'} (${item[description] || '-'})`,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value: isConvertIdToNumber ? changeNumericValueType(item.id) : item.id,
    }
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function mapSelectOptions(dictionary) {
  return Object.entries(dictionary).map(v => ({
    label: v[1],
    value: v[0],
  }))
}

export function sortListAlphabetically(list = [], key = 'label') {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return list.sort((a, b) => a[key].toLowerCase().localeCompare(b[key].toLowerCase()))
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function mapFixationFiles(files) {
  if (!files) return []
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return files.map(fileData => {
    return {
      fileId: fileData.fileId,
      name: fileData.file.name,
      id: fileData.id,
    }
  })
}
