import request, { IResponseList } from '@/api/request'
import { tableConfig } from './mock'
import {
  IUsecategory,
  IUsecategoryListParams,
  IUsecategoryParameters,
  IUsecategoryParametersParams,
} from '@/models/entities/usecategoies'

export const getTableConfig = () => Promise.resolve(tableConfig)

export const getUsecategoriesList = (params?: IUsecategoryListParams) => {
  return request<IResponseList<IUsecategory>>({
    url: `/api/v1/usecategory/usecategories`,
    method: 'GET',
    params,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getBaseUsecategoriesList = data => {
  return request({
    url: `/api/v1/usecategory/base_usecategories`,
    method: 'GET',
    params: data,
  })
}

export const getUsecategoryParameters = (params?: IUsecategoryParametersParams) => {
  return request<IResponseList<IUsecategoryParameters>>({
    url: `/api/v1/usecategory_parameters`,
    method: 'GET',
    params,
  })
}
