import { IConfigElementSimple } from '@/models/preset'
import { licenseAdditionalTab } from '@/views/licenses/config/licenseAdditionalTab/licenseAdditionalTab.fronctonf'
import { licenseBroadcastTab } from '@/views/licenses/config/licenseBroadcastTab/licenseBroadcastTab.frontconf'
import { licenseWorkTab } from '@/views/licenses/config/licenseWorkTab/licenseWorkTab.fronconf'
import { licenseDifferenceTab } from '@/views/licenses/config/licenseDifferenceTab/licenseDifferenceTab.frontconf'

export const licenseRegistryTabsBlockConfig: IConfigElementSimple = {
  name: 'Блок табов Реестр лицензий',
  type: 'TabsPreset',
  widgetId: 'licenseRegistry_tabs_tabs',
  prefs: {
    label: null,
    hidden: null,
    disabled: null,
    editable: null,
  },
  children: [licenseWorkTab, licenseAdditionalTab, licenseDifferenceTab, licenseBroadcastTab],
}
