import request, { IResponseList } from '@/api/request'
import {
  IWidgetConfigOverridePayload,
  IWidgetConfig,
  IWidgetConfigOverrideTreePayload,
  IWidgetConfigPayload,
} from '@/models/widgetConfig'

export const getWidgetConfigs = (params?: unknown) => {
  return request<IResponseList<IWidgetConfig>>({
    url: `/api/v1/widget/configs`,
    params,
  })
}

export const editWidgetConfig = (data: IWidgetConfigOverridePayload) => {
  return request({
    url: `/api/v1/widget/config/override`,
    method: 'POST',
    data,
  })
}

export const editWidgetPageConfig = (data: IWidgetConfigOverrideTreePayload) => {
  return request({
    url: `/api/v1/widget/config/override/tree`,
    method: 'POST',
    data,
  })
}

export const registerWidgetPageConfig = (data: IWidgetConfigPayload) => {
  return request({
    url: `/api/v1/widget/config/register`,
    method: 'POST',
    data,
  })
}
