export const tableConfig = {
  columns: [
    {
      field: 'name',
      label: 'Наименование',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '50%',
        },
      },
    },
    {
      field: 'id',
      label: 'Код',
      preset: 'table.columns.TableColumnDefaultPreset',
      presetParams: {
        options: {
          width: '70',
          sorter: true,
        },
      },
    },
    {
      field: 'shortName',
      label: 'Текстовый код',
      preset: 'table.columns.TableColumnTagsPreset',
      presetParams: {
        options: {
          sorter: true,
        },
      },
    },
    {
      field: 'description',
      label: 'Описание',
      preset: 'table.columns.TableColumnInfoTooltipPreset',
      presence: {
        readonly: false,
        resizable: true,
      },
    },
  ],
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const filters = []
