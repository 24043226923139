import request, { IResponseEntity, IResponseList } from '@/api/request'
import { IDocument, IDocumentFileRelation } from '@/models/entities/document'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getDocuments = params => {
  return request<IResponseList<IDocument>>({
    url: `/api/v1/document/documents/create`,
    method: 'GET',
    params,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getDocument = id => {
  return request<IResponseEntity<IDocument>>({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/document/documents/get/${id}`,
    method: 'GET',
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createDocument = data => {
  return request<IResponseEntity<IDocument>>({
    url: `/api/v1/document/documents/create`,
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const editDocument = (id, data) => {
  return request<IResponseEntity<IDocument>>({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/document/documents/update/${id}`,
    method: 'PUT',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deleteDocument = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/document/documents/delete/${id}`,
    method: 'DELETE',
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createFileRelation = data => {
  return request<IResponseEntity<IDocumentFileRelation>>({
    url: `/api/v1/document/files`,
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deleteFileRelation = id => {
  return request({
    url: `/api/v1/document/files`,
    method: 'DELETE',
    params: { id },
  })
}
