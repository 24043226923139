import { IConfigElementSimple } from '@/models/preset'
import { licenseRegistryTabsBlockConfig } from '@/views/licenses/config/tabsBlock.frontconf'

export const licenseRegistryConfig: IConfigElementSimple = {
  name: 'Реестр лицензий',
  type: 'RegistryPage',
  widgetId: 'licenseRegistry_registry',
  prefs: {
    label: null,
    hidden: null,
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'Таблица реестра лицензий',
      type: 'TablePreset',
      widgetId: 'licenseRegistry_table',
      prefs: {
        label: null,
        hidden: null,
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'колонка Номер',
          type: 'TableColumnPreset',
          widgetId: 'licenseRegistry_column_number',
          prefs: {
            label: 'Номер',
            hidden: null,
            disabled: null,
            editable: null,
            options: {
              width: '220',
            },
          },
          children: [],
        },
        {
          name: 'колонка Субъект',
          type: 'TableColumnPreset',
          widgetId: 'licenseRegistry_column_subject',
          prefs: {
            label: 'Субъект',
            hidden: null,
            disabled: null,
            editable: null,
            options: {
              width: '220',
            },
          },
          children: [],
        },
        {
          name: 'колонка Объект',
          type: 'TableColumnPreset',
          widgetId: 'licenseRegistry_column_useobject',
          prefs: {
            label: 'Объект',
            hidden: null,
            disabled: null,
            editable: null,
            options: {
              width: '220',
            },
          },
          children: [],
        },
        {
          name: 'колонка Статус лицензии',
          type: 'TableColumnPreset',
          widgetId: 'licenseRegistry_column_versionStatus',
          prefs: {
            label: 'Статус лицензии',
            hidden: null,
            disabled: null,
            editable: null,
            options: {
              width: '180',
            },
          },
          children: [],
        },
        {
          name: 'колонка Этап работы',
          type: 'TableColumnPreset',
          widgetId: 'licenseRegistry_column_workStatus',
          prefs: {
            label: 'Этап работы',
            hidden: null,
            disabled: null,
            editable: null,
            options: {
              width: '180',
            },
          },
          children: [],
        },
        {
          name: 'колонка Договор',
          type: 'TableColumnPreset',
          widgetId: 'licenseRegistry_column_contract',
          prefs: {
            label: 'Договор',
            hidden: null,
            disabled: null,
            editable: null,
            options: {
              width: '120',
            },
          },
          children: [],
        },
        {
          name: 'колонка Изменения',
          type: 'TableColumnPreset',
          widgetId: 'licenseRegistry_column_handlingStatus',
          prefs: {
            label: 'Изменения',
            hidden: null,
            disabled: null,
            editable: null,
            options: {
              width: '120',
            },
          },
          children: [],
        },
      ],
    },
    licenseRegistryTabsBlockConfig,
  ],
}
