import { type RouteLocationNormalized } from 'vue-router'
import { CONFIRMATION_ROUTE_NAME, LOGIN_ROUTE_NAME, PROFILES_ROUTE_NAME } from '@/router/constants'

type UnsavedPages = string | symbol
/**
 * Массив наименований путей, которые не должны быть сохранены
 * @type {string[]}
 */
const UNSAVED_PAGES: UnsavedPages[] = [
  PROFILES_ROUTE_NAME,
  LOGIN_ROUTE_NAME,
  CONFIRMATION_ROUTE_NAME,
]

//Сохраняет путь для редиректа после авторизации
export const saveForwardRoute = (route: RouteLocationNormalized) => {
  const { path, params, query, hash, name } = route
  const forwardRoute = { path, params, query, hash, name }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (UNSAVED_PAGES.includes(forwardRoute.name)) return

  const serializedForwardRoute = JSON.stringify(forwardRoute)
  sessionStorage.setItem('forwardRoute', serializedForwardRoute)
}

export const deleteForwardRoute = () => {
  sessionStorage.removeItem('forwardRoute')
}

export const getForwardRoute = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return JSON.parse(sessionStorage.getItem('forwardRoute'))
}
