import { IConfigElementSimple } from '@/models/preset'

export const licenseAdditionalTab: IConfigElementSimple = {
  name: 'Контейнер таба Дополнительная информация',
  type: 'ContainerPreset',
  widgetId: 'licenseRegistry_tabs_additional_container',
  prefs: {
    label: null,
    hidden: 'false',
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'Заголовок колонки 1 таба Дополнительная информация',
      type: 'TextPreset',
      widgetId: 'licenseRegistry_tabs_additional_container_column_1_header_text',
      prefs: {
        label: 'Направленность вещания',
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Таблица таба Сетка вещания',
      type: 'TablePreset',
      widgetId: 'licenseRegistry_tabs_additional_table',
      prefs: {
        label: null,
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'колонка Наименование СМИ',
          type: 'TableColumnPreset',
          widgetId: 'additional_table_column_mediaName',
          prefs: {
            label: 'Наименование СМИ',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Направление вещания',
          type: 'TableColumnPreset',
          widgetId: 'additional_table_column_broadcastDirection',
          prefs: {
            label: 'Направление вещания',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Процент',
          type: 'TableColumnPreset',
          widgetId: 'additional_table_column_percent',
          prefs: {
            label: 'Процент',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
      ],
    },
    {
      name: 'Заголовок колонки 2 таба Дополнительная информация',
      type: 'TextPreset',
      widgetId: 'licenseRegistry_tabs_additional_container_column_2_header_text',
      prefs: {
        label: 'Даты действия',
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Key value блок',
      type: 'TextPreset',
      widgetId: 'licenseRegistry_tabs_additional_container_column_2_kv_info_block',
      prefs: {
        label: null,
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'поле Дата начала',
          type: 'KeyValueStringPreset',
          widgetId: 'licenseRegistry_tabs_additional_container_column_2_date_begin',
          prefs: {
            label: 'Дата начала',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'поле Дата окончания',
          type: 'KeyValueStringPreset',
          widgetId: 'licenseRegistry_tabs_additional_container_column_2_date_end',
          prefs: {
            label: 'Дата окончания',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'поле Последнее приостановление/возобновление',
          type: 'KeyValueStringPreset',
          widgetId: 'licenseRegistry_tabs_additional_container_column_2_date_suspension',
          prefs: {
            label: 'Последнее приостановление/возобновление',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'поле Дате аннулирования',
          type: 'KeyValueStringPreset',
          widgetId: 'licenseRegistry_tabs_additional_container_column_2_date_annulation',
          prefs: {
            label: 'Дате аннулирования',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
      ],
    },
    {
      name: 'Заголовок колонки 3 таба Дополнительная информация',
      type: 'TextPreset',
      widgetId: 'licenseRegistry_tabs_additional_container_column_3_header_text',
      prefs: {
        label: 'Примечание',
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'поле Примечание',
      type: 'FormItemPreset',
      widgetId: 'licenseRegistry_tabs_additional_container_column_3_note',
      prefs: {
        label: null,
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [],
    },
    {
      name: 'Кнопка редактирования примечания',
      type: 'ButtonPreset',
      widgetId: 'licenseRegistry_tabs_additional_container_column_3_note_edit',
      prefs: {
        label: '',
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [],
    },
  ],
}
