import usecategoryList from './usecategoryListMock'
import objectTypeList from './objectTypeListMock'
import objectSubtypeList from './objectSubtypeListMock'
import regionRatioList from './regionRatioListMock'
import brandList from './brandListMock'
import request, { IResponseList } from '@/api/request'
import { IBank, IBankStatus } from '@/models/entities/bank'
import { ICountry } from '@/models/entities/country'

export { usecategoryList, objectTypeList, objectSubtypeList, regionRatioList, brandList }

export const getCountriesV3 = (params = {}) => {
  return request<IResponseList<ICountry>>({
    url: '/api/v3/dictionary/countries',
    method: 'GET',
    params,
  })
}

export const getCountryById = (id: number): Promise<ICountry> => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v2/dictionary/countries/by_id/${id}`,
    method: 'GET',
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createReceiverAccount = data => {
  return request({
    url: '/api/v1/dictionary/receiver_account',
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const editReceiverAccount = (id, data) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/dictionary/receiver_account/${id}`,
    method: 'PUT',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deleteReceiverAccount = (id, data) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/dictionary/receiver_account/${id}`,
    method: 'DELETE',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getReceiverAccount = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/dictionary/receiver_account/${id}`,
    method: 'GET',
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const hasReceiverAccountDependencies = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/dictionary/receiver_account_is_depends/${id}`,
    method: 'GET',
  })
}

export const getBanks = (params = {}) => {
  return request<IResponseList<IBank>>({
    url: '/api/v3/dictionary/banks',
    method: 'GET',
    params,
  })
}

export const getBankStatuses = (params = {}) => {
  return request<IResponseList<IBankStatus>>({
    url: '/api/v1/dictionary/bank_statuses',
    method: 'GET',
    params,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getReceiverAccounts = params => {
  return request({
    url: '/api/v1/dictionary/receiver_accounts',
    method: 'GET',
    params,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const getReceiverAccountStatuses = params => {
  return request({
    url: '/api/v1/dictionary/receiver_account_statuses',
    method: 'GET',
    params,
  })
}
