import { IConfigElementSimple } from '@/models/preset'

export const licenseBroadcastTab: IConfigElementSimple = {
  name: 'Контейнер таба Сетка вещания',
  type: 'ContainerPreset',
  widgetId: 'licenseRegistry_tabs_broadcastGridTab_container',
  prefs: {
    label: null,
    hidden: 'false',
    disabled: null,
    editable: null,
  },
  children: [
    {
      name: 'Таблица таба Сетка вещания',
      type: 'TablePreset',
      widgetId: 'licenseRegistry_tabs_broadcastGridTab_table',
      prefs: {
        label: null,
        hidden: 'false',
        disabled: null,
        editable: null,
      },
      children: [
        {
          name: 'колонка Регион',
          type: 'TableColumnPreset',
          widgetId: 'broadcastGridTab_column_region',
          prefs: {
            label: 'Регион',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Населённый пункт',
          type: 'TableColumnPreset',
          widgetId: 'broadcastGridTab_column_settlement',
          prefs: {
            label: 'Населённый пункт',
            hidden: 'false',
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Частота',
          type: 'TableColumnPreset',
          widgetId: 'broadcastGridTab_column_frequency',
          prefs: {
            label: 'Частота',
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Население, чел.',
          type: 'TableColumnPreset',
          widgetId: 'broadcastGridTab_column_population',
          prefs: {
            label: 'Население, чел.',
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
        {
          name: 'колонка Время вещания',
          type: 'TableColumnPreset',
          widgetId: 'broadcastGridTab_column_broadcastTime',
          prefs: {
            label: 'Время вещания',
            hidden: null,
            disabled: null,
            editable: null,
          },
          children: [],
        },
      ],
    },
  ],
}
