import request, { IResponseEntity, IResponseList } from '@/api/request'
import { type DataTableRowKey } from 'naive-ui'
import {
  IDepartmentAddress,
  IDepartmentAddressType,
  IDepartmentAteRegion,
  IDepartmentGroup,
  IDepartmentResponse,
  IDepartmentStatus,
  IDepartmentPerson,
  IDepartmentPersonCreate,
  IGetDepartmentPersonsParams,
} from '@/models/entities/department'
import { departmentRegistryConfig } from '@/views/departments/config/departmentRegistry.frontconf'

export const getDepartments = (params?: unknown) => {
  return request<IResponseList<IDepartmentResponse>>({
    url: `/api/v1/departments`,
    method: 'GET',
    params,
  })
}

export const getDepartment = (id: string) => {
  return request<IResponseEntity<IDepartmentResponse>>({
    url: `/api/v1/departments/${id}`,
    method: 'GET',
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createDepartment = data => {
  return request({
    url: `/api/v1/departments`,
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const editDepartment = (id, data) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/departments/${id}`,
    method: 'PUT',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deleteDepartment = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/departments/${id}`,
    method: 'DELETE',
  })
}

export const getDepartmentGroups = (params?: unknown) => {
  return request<IResponseList<IDepartmentGroup>>({
    url: `/api/v1/department_groups`,
    method: 'GET',
    params,
  })
}

export const getDepartmentGroup = (id: string) => {
  return request<IResponseEntity<IDepartmentGroup>>({
    url: `/api/v1/department_groups/${id}`,
    method: 'GET',
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const createDepartmentGroups = data => {
  return request({
    url: `/api/v1/department_groups`,
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const editDepartmentGroups = (id, data) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/department_groups/${id}`,
    method: 'PUT',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deleteDepartmentGroups = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/department_groups/${id}`,
    method: 'DELETE',
  })
}

export const getDepartmentStatuses = (params: unknown = {}) => {
  return request<IResponseList<IDepartmentStatus>>({
    url: `/api/v1/statuses`,
    method: 'GET',
    params,
  })
}

export const getDepartmentAte = (params: unknown = {}) => {
  return request<IResponseList<IDepartmentAteRegion>>({
    url: `/api/v1/regions`,
    method: 'GET',
    params,
  })
}

export const getDepartmentAddresses = (params: unknown) => {
  return request<IResponseList<IDepartmentAddress>>({
    url: `/api/v1/department/addresses`,
    method: 'GET',
    params,
  })
}

export const createDepartmentAddress = (data: unknown) => {
  return request({
    url: `/api/v1/department/addresses`,
    method: 'POST',
    data,
  })
}

export const updateDepartmentAddress = (id: DataTableRowKey, data: unknown) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/department/addresses/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteDepartmentAddress = (id: DataTableRowKey) => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/department/addresses/${id}`,
    method: 'DELETE',
  })
}

export const getDepartmentAddressTypes = (params?: unknown) => {
  return request<IResponseList<IDepartmentAddressType>>({
    url: `/api/v1/department/address_types`,
    method: 'GET',
    params,
  })
}

export const getDepartmentRegistryConfig = () => {
  return Promise.resolve(departmentRegistryConfig)
}

export const getDepartmentPersons = (params?: IGetDepartmentPersonsParams) => {
  return request<IResponseList<IDepartmentPerson>>({
    url: `/api/v1/departments/persons/search`,
    method: 'GET',
    params,
  })
}

export const createDepartmentPerson = (data: IDepartmentPersonCreate) => {
  return request<IResponseEntity<IDepartmentPerson>>({
    url: `/api/v1/departments/persons/create`,
    method: 'POST',
    data,
  })
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const deleteDepartmentPerson = id => {
  return request({
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    url: `/api/v1/departments/persons/${id}/delete`,
    method: 'DELETE',
  })
}
