import request, {
  IResponseEntity,
  IResponseList,
  IResponseListWithAggregations,
} from '@/api/request'
import { IAddRetranslationPayload, IAddUseobjectLicenseData } from './models'
import {
  IUseobject,
  IUseobjectGroup,
  IUseobjectGroupUpdate,
  IUseobjectGroupType,
  IUseobjectLicense,
  IUseobjectsZoneGroupPayload,
  IUseobjectsZonePayload,
  IUseobjectZone,
  IUseobjectZoneGroup,
  IGroupCoverage,
  IUseobjectPersonCreate,
  IUseobjectPerson,
  IUseobjectType,
} from '@/models/entities/useobject'
import { IRetranslation } from '@/models/entities/retranslation'
import {
  IGetUseobjectsQueryParams,
  ISearchUseobjectParams,
  IUseobjectV2,
} from '@/models/entities/useobjectV2'
import { IBroadcastEnvironment } from '@/models/entities/broadcastEnvironment'

export const getUseobjectV2 = (id: string) => {
  return request<IResponseEntity<IUseobjectV2>>({
    url: `/api/v2/useobject/useobjects/${id}`,
  })
}

export const removeUseobject = (id: IUseobjectV2['id']) => {
  return request({
    url: `/api/v2/useobject/useobjects/${id}`,
    method: 'DELETE',
  })
}

export const searchUseobjects = (params?: ISearchUseobjectParams) => {
  return request<IResponseListWithAggregations<IUseobjectV2>>({
    url: '/api/v1/useobject/useobjects/search',
    method: 'GET',
    params,
  })
}

export const getUseobjectLicenses = (params?: unknown) => {
  return request<IResponseList<IUseobjectLicense>>({
    url: `/api/v1/useobject/licenses`,
    method: 'GET',
    params,
  })
}

export const addUseobjectLicense = (data?: IAddUseobjectLicenseData) => {
  return request({
    url: `/api/v1/useobject/licenses`,
    method: 'POST',
    data,
  })
}

export const deleteUseobjectLicense = (id: IUseobjectLicense['id']) => {
  return request({
    url: `/api/v1/useobject/licenses/${id}`,
    method: 'DELETE',
  })
}

export const getUseobjectGroups = (data?: unknown) => {
  return request<IResponseList<IUseobjectGroup>>({
    url: `/api/v1/useobject/external/groups`,
    method: 'GET',
    params: data,
  })
}

export const getUseobjectGroupsTypes = (data?: unknown) => {
  return request<IResponseList<IUseobjectGroupType>>({
    url: `/api/v1/useobject/group_types`,
    method: 'GET',
    params: data,
  })
}

export const createExternalUseobjectGroup = (data: IUseobjectGroupUpdate) => {
  return request({
    url: `/api/v1/useobject/external/groups`,
    method: 'POST',
    data,
  })
}

export const getUseObjectGroupById = (id: string) => {
  return request<IUseobjectGroup>({
    url: `/api/v1/useobject/external/groups/${id}`,
    method: 'GET',
  })
}

export const deleteUseObjectGroup = (id: IUseobjectGroup['id']) => {
  return request({
    url: `/api/v1/useobject/external/groups/${id}`,
    method: 'DELETE',
  })
}

export const updateExternalUseObjectGroup = (
  id: IUseobjectGroup['id'],
  data: IUseobjectGroupUpdate,
) => {
  return request({
    url: `/api/v1/useobject/external/groups/${id}`,
    method: 'PUT',
    data,
  })
}

export const getRetranslations = (params?: unknown) => {
  return request<IResponseList<IRetranslation>>({
    url: '/api/v1/useobject/retranslations',
    params,
  })
}

export const createRetranslation = (data: IAddRetranslationPayload) => {
  return request<IResponseEntity<IRetranslation>>({
    url: '/api/v1/useobject/retranslations',
    method: 'POST',
    data,
  })
}

export const updateRetranslation = (id: IRetranslation['id'], data: IAddRetranslationPayload) => {
  return request<IResponseEntity<IRetranslation>>({
    url: `/api/v1/useobject/retranslations/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteRetranslation = (id: IRetranslation['id']) => {
  return request<IResponseEntity<IRetranslation>>({
    url: `/api/v1/useobject/retranslations/${id}`,
    method: 'DELETE',
  })
}

export const getUseobjectsV2 = (params?: IGetUseobjectsQueryParams) => {
  return request<IResponseList<IUseobjectV2>>({
    url: '/api/v2/useobject/useobjects',
    params,
  })
}

export const getBroadcastEnvironments = (params?: unknown) => {
  return request<IResponseList<IBroadcastEnvironment>>({
    url: '/api/v1/useobject/broadcast_environments',
    params,
  })
}

export const getUseobjectZones = (id: IUseobject['id'], params?: unknown) => {
  return request<IResponseList<IUseobjectZoneGroup>>({
    url: `/api/v1/useobject/zones/${id}`,
    method: 'GET',
    params,
  })
}

export const createUseobjectZoneGroup = (data: IUseobjectsZoneGroupPayload) => {
  return request<IResponseEntity<IUseobjectZoneGroup>>({
    url: '/api/v1/useobject/zone_groups',
    method: 'POST',
    data,
  })
}

export const updateUseobjectZoneGroup = (
  id: IUseobjectZoneGroup['id'],
  data: IUseobjectsZoneGroupPayload,
) => {
  return request<IResponseEntity<IUseobjectZoneGroup>>({
    url: `/api/v1/useobject/zone_groups/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteUseobjectZoneGroup = (id: IUseobjectZoneGroup['id']) => {
  return request({
    url: `/api/v1/useobject/zone_groups/${id}`,
    method: 'DELETE',
  })
}

export const createUseobjectZone = (data: IUseobjectsZonePayload) => {
  return request<IResponseEntity<IUseobjectZone>>({
    url: '/api/v1/useobjects/zone',
    method: 'POST',
    data,
  })
}

export const updateUseobjectZone = (id: IUseobjectZone['id'], data: IUseobjectsZonePayload) => {
  return request<IResponseEntity<IUseobjectZone>>({
    url: `/api/v1/useobjects/zone/${id}`,
    method: 'PUT',
    data,
  })
}

export const deleteUseobjectZone = (id: IUseobjectZone['id']) => {
  return request({
    url: `/api/v1/useobjects/zone/${id}`,
    method: 'DELETE',
  })
}

export const getGroupCoverages = (params?: unknown) => {
  return request<IResponseList<IGroupCoverage>>({
    url: '/api/v1/group_coverages',
    method: 'GET',
    params,
  })
}

export const createUseobjectPerson = (data: IUseobjectPersonCreate) => {
  return request<IResponseEntity<IUseobjectPerson>>({
    url: `/api/v1/persons`,
    method: 'POST',
    data,
  })
}

export const getUseobjectPersons = (params?: unknown) => {
  return request<IResponseList<IUseobjectPerson>>({
    url: '/api/v1/persons',
    method: 'GET',
    params,
  })
}

export const createPhysicalUseobject = (data: unknown) => {
  return request<IResponseEntity<IUseobjectV2>>({
    url: '/api/v1/useobject/useobjects/physical',
    method: 'POST',
    data,
  })
}

export const editPhysicalUseobject = (id: string, data: unknown) => {
  return request({
    url: `/api/v2/useobject/useobjects/physical/${id}`,
    method: 'PUT',
    data,
  })
}

export const createMediaUseobject = (data: unknown) => {
  return request({
    url: '/api/v1/useobject/useobjects/media',
    method: 'POST',
    data,
  })
}

export const editMediaUseobject = (id: string, data: unknown) => {
  return request({
    url: `/api/v2/useobject/useobjects/media/${id}`,
    method: 'PUT',
    data,
  })
}

export const getTypesForSelect = (params?: unknown) => {
  return request<IResponseList<IUseobjectType>>({
    url: `/api/v1/useobject/types`,
    method: 'GET',
    params,
  })
}

export const getClasses = () => {
  return request({
    url: `/api/v1/useobject/classes`,
    method: 'GET',
  })
}

export const createUseobjectGroup = (data: unknown) => {
  return request({
    url: `/api/v1/useobject/useobject_groups`,
    method: 'POST',
    data,
  })
}

export const editUseobjectGroup = (id: string, data: unknown) => {
  return request({
    url: `/api/v1/useobject/useobject_groups/${id}`,
    method: 'PATCH',
    data,
  })
}
